<template>
  <div>
    <v-snackbar v-model="snackbar" color="#3CB043" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <PageLoader v-bind:storage="appLoading" />

    <v-layout wrap justify-center fill-height>
      <v-flex xs12 align-self-center>
        <!-- <v-img
            :height="
              $vuetify.breakpoint.name == 'xs'
                ? '1430px'
                : $vuetify.breakpoint.name == 'sm'
                ? '1500px'
                : $vuetify.breakpoint.name == 'md'
                ? '800px'
                : $vuetify.breakpoint.name == 'xl'
                ? '1100px'
                : $vuetify.breakpoint.name == 'lg'
                ? '780px'
                : '780px'
            "
            :width="
              $vuetify.breakpoint.name == 'xs'
                ? '480px'
                : $vuetify.breakpoint.name == 'sm'
                ? '1000px'
                : $vuetify.breakpoint.name == 'md'
                ? '1100px'
                : $vuetify.breakpoint.name == 'xl'
                ? '3000px'
                : $vuetify.breakpoint.name == 'lg'
                ? '1500px'
                : '1600px'
            "
            src="../assets/images/Group 69.png"
          > -->
        <v-layout wrap justify-center>
          <v-flex xs10 pl-6 pt-14 text-left>
            <span
              style="
                font-family: poppinsbold;
                color: black;
                text-align: justify;
                font-size: 36px;
                font-weight: bolder;
              "
              >Contact

              <span
                style="
                  font-family: poppinsbold;
                  color: #3cb043;
                  text-align: justify;
                  font-size: 36px;
                  font-weight: bolder;
                "
                >Us</span
              ></span
            >
            <v-flex xs1>
              <v-progress-linear
                align-center
                height="2"
                color="#3CB043"
                value="100"
                width="80%"
              ></v-progress-linear>
            </v-flex>
          </v-flex>
        </v-layout>
        <v-form v-model="addcat" ref="addcat" @submit.prevent>
          <v-layout pl-xs-6 pl-lg-6 pl-md-0 wrap justify-end>
            <v-flex
              lg6
              md6
              xs12
              text-right
              data-aos="slide-right"
              data-aos-ease="ease"
              data-aos-duration="1500"
              data-aos-delay="500"
            >
              <v-layout
                pl-xs-0
                pl-md-0
                pl-lg-0
                pl-3
                wrap
                justify-start
                style="
                  font-family: homefont;
                  color: black;
                  text-align: justify;
                  font-weight: bolder;
                "
              >
                <v-flex pt-10 xs10 text-left>
                  <span
                    style="
                      font-family: poppinsmedium;
                      color: black;
                      font-size: 18px;
                    "
                    >
                    <!-- Feel Free to contact us anytime , We will get back to you
                    as soon as we can! -->
                    Please contact us with any queries you have
                  </span>
                </v-flex>
                <v-flex xs12 lg8 pt-7 text-left>
                  <span style="font-family: poppinsregular; font-size: 14px"
                    >Name</span
                  >
                  <v-text-field
                    type="text"
                    v-model="contact.name"
                    color="blue"
                    placeholder="Enter Name"
                    regular
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 lg8 pt-7 text-left>
                  <span style="font-family: poppinsregular; font-size: 14px"
                    >E-mail</span
                  >

                  <v-text-field
                    color="blue"
                    placeholder="Enter E-mail"
                    regular
                    :rules="emailRules"
                    v-model="contact.email"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 lg8 pt-7 text-left>
                  <span style="font-family: poppinsregular; font-size: 14px"
                    >Phone Number</span
                  >
                  <v-text-field
                    color="blue"
                    placeholder="Enter Phone Number"
                    regular
                    type="number"
                    :rules="phnoRules"
                    v-model="contact.phoneNo"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 lg8 pt-7 text-left>
                  <span style="font-family: poppinsregular; font-size: 14px"
                    >Message</span
                  >

                  <v-text-field
                    color="blue"
                    placeholder="Enter Message"
                    regular
                    v-model="contact.msge"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 lg8 text-right py-5>
                  <v-btn
                    dark
                    color="#3CB043"
                    height="30px"
                    width="120px"
                    :ripple="false"
                    depressed
                    @click="validateInput()"
                  >
                    <span style="font-size: 12px; font-weight: bold;font-family: poppinsmedium;">
                      SUBMIT</span
                    >
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md5 lg5>
              <v-layout wrap justify-end>
                <v-flex xs12 text-right pt-10>
                  <v-card class="rounded-0" color="black" height="110%">
                    <v-layout wrap justify-space-between>
                      <v-flex pt-4 xs12 text-center>
                        <span
                          style="
                            font-family: poppinsbold;
                            color: #3cb043;
                            text-align: justify;
                            font-size: 36px;
                            font-weight: bolder;
                          "
                          >Info</span
                        >
                      </v-flex>

                      <v-layout wrap fill-height justify-center>
                        <v-flex
                          xs11
                          pa-4
                          data-aos="slide-left"
                          data-aos-ease="ease"
                          data-aos-duration="1500"
                          data-aos-delay="500"
                        >
                          <v-layout wrap justify-center>
                            <v-flex
                              align-self-end
                              xs12
                              text-center
                              style="
                                font-family: poppinsmedium;
                                color: white;

                                line-height: 1.5;
                                font-size: 16px;
                                font-weight: bolder;
                              "
                            >
                              <span
                                >We value your feedback and strive to provide
                                excellent customer service !
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex
                          xs7
                          pt-2
                          data-aos="slide-left"
                          data-aos-ease="ease"
                          data-aos-duration="1500"
                          data-aos-delay="500"
                        >
                          <v-img
                            contain
                            src="./../../assets/images/cimg3.jpg"
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                    <v-layout pt-3 wrap justify-end>
                      <v-flex xs3 pr-10>
                        <!-- <v-img
                            contain
                            src="../assets/images/patternx.png"
                          ></v-img> -->
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
        <!-- </v-img> -->
      </v-flex>
    </v-layout>
  </div>
</template>
         
<script>
import axios from "axios";

export default {
  data() {
    return {
      addcat: false,
      name: null,
      phoneNo: null,
      email: null,
      msg: null,
      location: null,
      list: [],
      // timeout: 5000,
      snackbar: false,
      appLoading: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      roleRules: [(v) => !!v || "Role is required"],
      phnoRules: [
        (v) => !!v || "phone is required",
        (v) => (v && v.length <= 10) || "invalid number",
        (v) => (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],

      contact: {
        name: null,
        email: null,
        phoneNo: null,
        msge: null,
      },
    };
  },
  mounted() {},
  methods: {
    add() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/add/contactUs",
        data: {
          name: this.contact.name,
          email: this.contact.email,
          phoneNo: this.contact.phoneNo,
          message: this.contact.msge,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.msg = "Message Send  Successfully";
            this.snackbar = true;
            this.appLoading = false;
            this.$refs.addcat.resetValidation();
            this.contact.name = "";
            this.contact.email = "";
            this.contact.phoneNo = "";
            this.contact.msge = "";
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;

          console.log(err);
        });
    },
    validateInput() {
      if (!this.contact.name) {
        this.msg = "Please Provide name";
        this.snackbar = true;
        return;
      } else if (!this.contact.phoneNo) {
        this.msg = "Please Provide phone number";
        this.snackbar = true;
        return;
      } else if (!this.contact.email) {
        this.msg = "Please Provide email";
        this.snackbar = true;
        return;
      } else if (!this.contact.msge) {
        this.msg = "Please Provide message";
        this.snackbar = true;
        return;
      } else {
        this.add();
      }
    },
  },
};
</script>
  
  
  
  
  
        
<style scoped>
.cr {
  position: absolute;
  width: 562px;
  height: 333px;
  left: 1036px;
  top: 1668px;

  background: #fee60d;
}

.right-aligned-img {
  float: right;
}

* {
  box-sizing: border-box;
}

p {
  font-size: 20px;
  font-family: sans-serif;
  color: #6439a9;
}

.container {
  padding: 100px 30px;
  width: 100%;
  margin: 0 auto;
  max-width: 900px;
}

.image-stack::after {
  content: " ";
  display: table;
  clear: both;
}

.grad {
  background-image: linear-gradient(to right, yellow, yellow);
}

.grad2 {
  background-image: linear-gradient(to right, #0b0a1f, #0b0a1f);
}

.bg3 {
  background-image: linear-gradient(to right, #0b0a1f, #0b0a1f);
}

.image-stack__item--bottom {
  float: right;
  width: 75%;
}

img {
  width: 100%;
}
</style>
        
        
        